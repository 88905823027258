import React from "react";
import PropTypes from 'prop-types';
import {  Paragraph, ResponsiveContext, Anchor,Box, Footer, Text, ThemeContext } from "grommet";

import {Button, Image, Heading } from 'grommet';
import { useStaticQuery, graphql } from 'gatsby';


const BlogCardImage = ({ post }) => {


  const { author, date, title, featuredImage, category } = post.frontmatter;

  const { allImageSharp } = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 600,duotone: { highlight: "#009d52", shadow: "#083721" }, toFormat: PNG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              originalName
            }
          }
        }
      }
    }
  `);

  const image = allImageSharp.edges.find(
    edge => edge.node.fluid.originalName === featuredImage
  );
  if (!image) {
    return null;
  }

  return (
          <Box  align="start" justify="between" pad="medium" margin={{horizontal:"medium"}} background={`url(${image.node.fluid.src})`}>
            <Box>
              <Heading size="xxlarge" color="white">{title}</Heading>
              <Paragraph size="large" color="white">Hier komt de samenvatting tekst, iets langer en klein beetje vullend. Mag best wat karakters bevattenn. En kan over drie regels uitspreiden</Paragraph>
            </Box>
              <Button plain color="white" margin={{bottom:"medium"}} label="Read Article"/>
          </Box>
        );
};

export default BlogCardImage;
