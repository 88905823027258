import React from "react";
import PropTypes from 'prop-types';
import {  Paragraph, ResponsiveContext, Anchor,Box, Footer, Text, ThemeContext } from "grommet";
import Link from "../link";
import {Button, Image, Heading } from 'grommet';
import { useStaticQuery, graphql } from 'gatsby';
import { FeatureImage } from '../Image';

const CardHeader = ({ post }) => {


  const { author, date, title, featureImage, category, path } = post;



  return (
          <Box  align="start" justify="between" width="large" pad="medium" margin={{horizontal:"medium"}} >
            <Box >
              <Link to={path}><FeatureImage src={featureImage} alt={title} /></Link>
              <Link to={path}><Heading textAlign="center" size="xsmall" color="white">{title}</Heading></Link>
            </Box>

          </Box>
        );
};



export default CardHeader;
