module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663e99","theme_color":"#663e99","display":"minimal-ui","icon":"src/images/gatsby-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"root":"/home/martin/dev/pregly-grommet","gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1240,"quality":100,"linkImagesToOriginal":false,"disableBgImageOnAlpha":true}}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
