import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Image } from 'grommet';

export const FeatureImage = ({ src, alt, ...rest }) => {
  const { allImageSharp } = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 1240) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              originalName
            }
          }
        }
      }
    }
  `);

  const image = allImageSharp.edges.find(
    edge => edge.node.fluid.originalName === src
  );
  if (!image) {
    return null;
  }
  return <Image fill="horizontal" src={image.node.fluid.src} alt={alt} {...rest} />;
};

FeatureImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

export default FeatureImage;
