import React from "react";
import PropTypes from 'prop-types';
import { Heading, Paragraph, ResponsiveContext, Anchor,Box, Footer, Text, ThemeContext } from "grommet";
import Link from "../link";
import { FeatureImage } from '../Image';
import { Image } from 'grommet';
const _ = require("lodash")

const BlogCard = ({ post }) => {


  const { author, date, title, featuredImage, category, path } = post.frontmatter;

  return (
          <Box  align="start" width="fit"  margin={{horizontal:"medium"}} >
            <Link to={path}><FeatureImage src={featuredImage} alt={title} /></Link>
                <Box direction="row" margin={{ top: 'small' }}>
                    <Link color="status-warning" to={`/blog/category/${_.kebabCase(category)}`} >{category}</Link>
                </Box>
                <Link to={path}><Heading size="xsmall">{title}</Heading></Link>
              <Text  margin={{ bottom: 'small' }}>{post.excerpt}</Text>
              <Text color="dark-3" margin={{ bottom: 'large' }}>{date}</Text>
          </Box>
        );
};

export default BlogCard;
